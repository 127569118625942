import { useEffect, useState } from "react";
import { useReportStyles } from "components/tables/ReportTables/styles";

export const useDaStatusHighlight = (userStatus: number) => {
  const classes = useReportStyles();
  const [daStatusHighlight, setDaStatusHighlight] = useState({
    alertClassName: classes.orangeAlert,
    className: classes.yellowCell,
    severity: "warning",
  });

  useEffect(() => {
    switch (userStatus) {
      case 1:
        setDaStatusHighlight({
          alertClassName: classes.greenAlert,
          className: classes.greenCell,
          severity: "success",
        });
        break;
      case 0:
        setDaStatusHighlight({
          alertClassName: classes.orangeAlert,
          className: classes.yellowCell,
          severity: "warning",
        });
        break;
      default:
        setDaStatusHighlight({
          alertClassName: classes.redAlert,
          className: classes.redCell,
          severity: "error",
        });
    }
  }, [
    userStatus,
    classes.greenAlert,
    classes.greenCell,
    classes.orangeAlert,
    classes.redAlert,
    classes.redCell,
    classes.yellowCell,
  ]);

  return daStatusHighlight;
};
