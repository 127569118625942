import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { PdfRenderRow } from "./PdfRenderRow";
import { InfoBox } from "components/elements/InfoBox";
import { ReportRecord } from "types/Report";
import LogoPng from "icon/LogoPng";

export const PdfRenderTable = () => {
  const classes = useStyles();
  const { currentReport, currentReportStation, currentReportDate, hasSecondDriver } =
    useTypeSelectors((state) => state.dasBoard);

  return (
    <Box id="tablePdf">
      <Box className={classes.mainHead}>
        <Box className={classes.logoBox}>
          <LogoPng width={150} height={52} isBlue/>
        </Box>
        <Box>
          <InfoBox label={"Date"} value={currentReportDate} horizontal />
          <InfoBox label={"Station"} value={currentReportStation?.title} horizontal />
        </Box>
      </Box>
      <Table style={{ zIndex: 999, background: "white", maxHeight: "70vh" }}>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell className={classes.headCell}>
              <Typography variant="h6">№</Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Typography variant="h6">Fleet No.</Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Typography variant="h6">Route</Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Typography variant="h6">Wave</Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Typography variant="h6">DA</Typography>
            </TableCell>
            {hasSecondDriver && (
              <TableCell className={classes.headCell}>
                <Typography variant="h6">DA 2</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentReport.length > 0
            ? currentReport.map((row: ReportRecord, index: number) => (
                <PdfRenderRow reportRecord={row} station={currentReportStation?.id} index={index} hasSecondDriver={hasSecondDriver} />
              ))
            : null}
        </TableBody>
      </Table>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  logoBox: {
    margin: theme.spacing(1),
  },
  headCell: {
    fontWeight: 700,
  },
  head: {
    background: "#696969",
  },
}));
