import { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { tableRowsCreate } from "components/tables/tableRows";
import { ReportRow } from "components/tables/ReportTables/ReportRow";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { Box, TableSortLabel, Typography } from "@material-ui/core";
import { useReportStyles } from "./styles";
import { getFilteredFormData } from "utils/filters";
import { useDispatch } from "react-redux";
import { removeReportRecordAction, updateReportRecordAction } from "actions/daDailyReportsAction";
import { ReportRecord } from "types/Report";
import { Colors } from "constants/constants";

interface PropsType {
  searchValue: string;
}

const ReportTableSortLabel = ({
  rowId,
  rowLabel,
  order,
  orderBy,
  hasSortLabel,
  handleRequestSort,
}: any) => {
  const classes = useReportStyles();
  return (
    <TableSortLabel
      disabled={!hasSortLabel}
      active={orderBy === rowId}
      direction={orderBy === rowId ? order : "asc"}
      onClick={() => handleRequestSort(rowId)}
      className={classes.labelwithArrow}
    >
      <Typography className={classes.fontWeight}>{rowLabel}</Typography>
    </TableSortLabel>
  );
};

export const ReportTable = ({ searchValue }: PropsType) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const { currentReport, reportListLoading, hasSecondDriver } = useTypeSelectors(
    (state) => state.dasBoard,
  );
  const [filteredData, setFilteredData] = useState<ReportRecord[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<any>("created");

  const handleRequestSort = (column_id: string | undefined) => {
    const isAsc = orderBy === column_id && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column_id);
  };

  const handleDeleteRow = (recordId: number) => {
    if (recordId) {
      dispatch(removeReportRecordAction(recordId));
    }
  };

  const handleUpdateRow = (recordId: number, data: ReportRecord) => {
    if (recordId) {
      dispatch(updateReportRecordAction(recordId, data));
    }
  };

  useEffect(() => {
    setFilteredData(getFilteredFormData({ searchValue, currentReport }));
  }, [currentReport, searchValue]);

  if (reportListLoading) return null;
  return (
    <Box className={classes.root} id="App">
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableCell
              key={"delete"}
              className={`${classes.tableCellSmall}`}
              style={{ width: 50 }}
            />
            {tableRowsCreate
              .slice(1, tableRowsCreate.length)
              .filter(
                (row) =>
                  !(
                    (row.id === "second_driver" || row.id === "second_da_status") &&
                    !hasSecondDriver
                  ),
              )
              .map((row) =>
                row.field === "big" ? (
                  <TableCell
                    key={row.id}
                    className={`${classes.tableCellAlign}
                    ${
                      row.id === "driver"
                        ? classes.tableCellBigSticky
                        : row.id === "actual_DA"
                          ? classes.tableCellComment
                          : classes.tableCellBig
                    }`}
                  >
                    <ReportTableSortLabel
                      rowId={row.id}
                      rowLabel={row.label}
                      order={order}
                      orderBy={orderBy}
                      hasSortLabel={row?.hasSortLabel}
                      handleRequestSort={handleRequestSort}
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    key={row.id}
                    className={`${classes.tableCellSmall} ${classes.tableCellAlign} ${
                      row.id === "created" && classes.tableCellVerySmall
                    }`}
                    style={{
                      ...(row.id === "cod" && {
                        background: "#e0e0e0",
                        borderLeft: `1px solid ${Colors.MAIN}`,
                      }),
                    }}
                  >
                    <ReportTableSortLabel
                      rowId={row.id}
                      rowLabel={row.label}
                      order={order}
                      orderBy={orderBy}
                      hasSortLabel={row?.hasSortLabel}
                      handleRequestSort={handleRequestSort}
                    />
                  </TableCell>
                ),
              )}
          </TableHead>
          <TableBody className={classes.body}>
            {searchValue.length
              ? filteredData
                  .sort(getComparator(order, orderBy))
                  .map((row: ReportRecord, index: number) => (
                    <ReportRow
                      key={row.id}
                      reportRecord={row}
                      index={currentReport.findIndex((el: ReportRecord) => row.id === el.id)}
                      onDelete={handleDeleteRow}
                      onUpdate={handleUpdateRow}
                    />
                  ))
              : currentReport
                  .sort(getComparator(order, orderBy))
                  .map((row: ReportRecord, index: number) => (
                    <ReportRow
                      key={row.id}
                      reportRecord={row}
                      index={index}
                      onDelete={handleDeleteRow}
                      onUpdate={handleUpdateRow}
                    />
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function descendingComparator<T>(a: any, b: any, orderBy: keyof T) {
  if (a[orderBy] === null) return 1;
  if (b[orderBy] === null) return -1;
  if (b[orderBy] < a[orderBy] || b[orderBy]?.first_name < a[orderBy]?.first_name) {
    return -1;
  }
  if (b[orderBy] > a[orderBy] || b[orderBy]?.first_name > a[orderBy]?.first_name) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: "asc" | "desc",
  orderBy: keyof ReportRecord,
): (a: ReportRecord, b: ReportRecord) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
