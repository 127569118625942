import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    cursor: "default",
    textAlign: "center",
    padding: theme.spacing(1.5, 1.5, 1.5, 4),
  },
  tableHeadWhiteCell: {
    background: Colors.WHITE,
  },
  tableHeadTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
}));
