import { initialDaAndFleetState } from "store/initialStates";
import { DaAndFleetState } from "types/DaAndFleet";
import { ReducersInterfaceAction } from "types/Reducers";
import { DaAndFleetConstants } from "constants/constants";

export const daAndFleetReducer = (
  state = initialDaAndFleetState,
  action: ReducersInterfaceAction,
): DaAndFleetState => {
  switch (action.type) {
    case DaAndFleetConstants.DA_FLEET_GET_ALL_DRIVERS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        driverData: {
          ...state?.driverData,
          driverList: action.payload?.results,
          driverListCount: action.payload?.count,
        },
      };
    case DaAndFleetConstants.DA_FLEET_GET_ALL_FLEEETS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        fleetData: {
          ...state?.fleetData,
          fleetList: action.payload?.results,
          fleetListCount: action.payload?.count,
        },
      };
    case DaAndFleetConstants.DA_FLEET_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case DaAndFleetConstants.DA_FLEET_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case DaAndFleetConstants.DA_FLEET_DA_SET_FILTERS:
      return {
        ...state,
        driverData: {
          ...state?.driverData,
          driverListFilters: {
            ...state?.driverData?.driverListFilters,
            ...action.payload,
          },
        },
      };
    case DaAndFleetConstants.DA_FLEET_FLEET_SET_FILTERS:
      return {
        ...state,
        fleetData: {
          ...state?.fleetData,
          fleetListFilters: {
            ...state?.fleetData?.fleetListFilters,
            ...action.payload,
          },
        },
      };
    case DaAndFleetConstants.DA_FLEET_CHANGE_PAGE_LIST_TYPE:
      return {
        ...state,
        pageListType: action.payload,
      };
    case DaAndFleetConstants.DA_FEET_SET_FLEET_ID_LIST:
      return {
        ...state,
        fleetData: {
          ...state?.fleetData,
          idListForUpdate: action.payload,
        },
      };
    case DaAndFleetConstants.DA_FEET_SET_DA_ID_LIST:
      return {
        ...state,
        driverData: {
          ...state?.driverData,
          idListForUpdate: [...action.payload],
        },
      };
    case DaAndFleetConstants.DA_FEET_SET_FLEET_ID_LIST:
      return {
        ...state,
        fleetData: {
          ...state?.fleetData,
          idListForUpdate: action.payload,
        },
      };
    case DaAndFleetConstants.UPDATE_DA_SUCCESS:
      return {
        ...state,
        loadingData: false,
        driverData: {
          ...state?.driverData,
          driverList: action.payload,
          idListForUpdate: [],
        },
      };
    default:
      return state;
  }
};
