import { useTypeSelectors } from "../hooks/useTypeSelectors";
import { useLocation } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Drawer, IconButton, Typography } from "@material-ui/core";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import TableChartIcon from "@material-ui/icons/TableChart";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Colors, DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR } from "../constants/constants";
import theme from "theme";
import { FC, ReactNode, useState } from "react";
import { AppBarComponent } from "./AppBar";
import { useNav } from "hooks/useNav";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import DeveloperBoard from "@material-ui/icons/DeveloperBoard";
import Assessment from "@material-ui/icons/Assessment";
import { CollapseSubMenu } from "./elements/CollapseSubMenu";

const DRAWER_MIN = 64;
const DRAWER_MAX = 200;

interface DrawerComponentProps {
  children: ReactNode;
}

export const DrawerComponent: FC<DrawerComponentProps> = ({ children }) => {
  const classes = useStyles();
  const params = useLocation();
  const { userData } = useTypeSelectors((state) => state.auth);
  const {
    viewDashboard,
    viewDashboardTrends,
    viewImport,
    viewReports,
    viewScorecard,
    viewCheck,
    viewAct,
    viewAmznScoreboard,
    viewPerfomance,
  } = useNav();
  const [drawerWidth, setDrawerWidth] = useState(DRAWER_MAX);
  const isMenuOpen = drawerWidth === DRAWER_MAX;

  const handleChangeDrawerWidth = () => {
    isMenuOpen ? setDrawerWidth(DRAWER_MIN) : setDrawerWidth(DRAWER_MAX);
  };

  const listMenu = [
    {
      Icon: DashboardIcon,
      text: "Dashboard",
      link: viewDashboard,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/dashboard"],
      subList: [],
    },
    {
      Icon: DeveloperBoard,
      text: "Trends",
      link: () => null,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: [
        "/dashboard/routes",
        "/dashboard/deliveries",
        "/dashboard/returns",
        "/dashboard/missing",
      ],
      subList: [
        {
          Icon: BarChartIcon,
          text: "Routes",
          link: () => viewDashboardTrends("routes"),
          path: "/dashboard/routes",
        },
        {
          Icon: BarChartIcon,
          text: "Deliveries",
          link: () => viewDashboardTrends("deliveries"),
          path: "/dashboard/deliveries",
        },
        {
          Icon: BarChartIcon,
          text: "Returns",
          link: () => viewDashboardTrends("returns"),
          path: "/dashboard/returns",
        },
        {
          Icon: BarChartIcon,
          text: "Missing",
          link: () => viewDashboardTrends("missing"),
          path: "/dashboard/missing",
        },
      ],
    },
    // {
    //   Icon: MoreHoriz,
    //   text: "DA and Fleets",
    //   link: viewDaAndFleets,
    //   permission: [MANAGER, SUPERADMIN, SUPERVISOR],
    //   pathnames: ["/da-and-fleets"],
    //   subList: [],
    // },
    {
      Icon: DateRangeOutlinedIcon,
      text: "Shift tracker",
      link: viewReports,
      permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/das-reports"],
      subList: [],
    },
    {
      Icon: DeveloperBoard,
      text: "AMZL reports",
      link: () => null,
      permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/check", "/act", "/amzn-scoreboard", "/performance"],
      subList: [
        {
          Icon: BarChartIcon,
          text: "Performance",
          link: viewPerfomance,
          path: "/performance",
        },
        {
          Icon: Assessment,
          text: "Scoreboard",
          link: viewAmznScoreboard,
          path: "/amzn-scoreboard",
        },
        {
          Icon: TableChartIcon,
          text: "Check (AMZL)",
          link: viewCheck,
          path: "/check",
        },
        {
          Icon: CallToActionIcon,
          text: "Act (AMZL)",
          link: viewAct,
          path: "/act",
        },
      ],
    },
    // {
    //   Icon: DeveloperBoard,
    //   text: "IKEA reports",
    //   link: () => null,
    //   permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
    //   pathnames: ["/check-ikea", "/act-ikea"],
    //   subList: [
    //     {
    //       Icon: TableChartIcon,
    //       text: "Check (IKEA)",
    //       link: viewIkeaCheck,
    //       path: "/check-ikea",
    //     },
    //     {
    //       Icon: CallToActionIcon,
    //       text: "Act (IKEA)",
    //       link: viewIkeaAct,
    //       path: "/act-ikea",
    //     },
    //   ],
    // },
    {
      Icon: BarChartIcon,
      text: "Pulse",
      link: viewScorecard,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/scorecard"],
      subList: [],
    },
    // {
    //   Icon: BarChartIcon,
    //   text: "Graphs",
    //   link: viewGraphs,
    //   permission: [MANAGER, SUPERADMIN, SUPERVISOR],
    //   pathnames: ["/graphs"],
    //   subList: [],
    // },
  ];

  const listBottomMenu = [
    {
      Icon: PublishOutlinedIcon,
      text: "Import",
      link: viewImport,
      permission: [SUPERVISOR, MANAGER, SUPERADMIN],
      path: "/import",
    },
  ];

  return (
    <>
      <AppBarComponent />
      <Box className={classes.body}>
        <Drawer
          style={{
            width: drawerWidth,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
          variant="persistent"
          anchor="left"
          open={true}
        >
          <Box
            style={{
              width: drawerWidth,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              background: theme.palette.primary.main,
            }}
          >
            <Box className={classes.drawer}>
              {listMenu.map(({ Icon, ...el }) => {
                const acceptPermission = el.permission.some((element) => element === userData.role);
                const isActive = el.pathnames.includes(params?.pathname);

                return acceptPermission ? (
                  !el.subList.length ? (
                    <Button
                      fullWidth
                      color="primary"
                      variant={isActive ? "contained" : "text"}
                      onClick={el.link}
                      disableElevation
                      className={classes.linkMenu}
                      style={!isMenuOpen ? { justifyContent: "center" } : {}}
                    >
                      <Icon style={{ color: "currentColor" }} />
                      {isMenuOpen && (
                        <Typography variant="body2" style={{ marginLeft: 10 }}>
                          {el.text}
                        </Typography>
                      )}
                    </Button>
                  ) : (
                    <>
                      <CollapseSubMenu
                        Icon={Icon}
                        data={el}
                        mainClasses={classes}
                        isOptionActive={isActive}
                        isMenuOpen={isMenuOpen}
                      />
                    </>
                  )
                ) : null;
              })}
            </Box>
            <Box className={classes.buttonDrawerWidth}>
              {listBottomMenu.map(({ Icon, ...el }) => {
                const acceptPermission = el.permission.some((element) => element === userData.role);
                const isActive =
                  params?.pathname === el.path ||
                  params?.pathname.split("/")[1] === el.path.split("/")[1];
                return acceptPermission ? (
                  <Button
                    fullWidth
                    color="primary"
                    variant={isActive ? "contained" : "text"}
                    onClick={el.link}
                    disableElevation
                    className={classes.linkMenu}
                    style={!isMenuOpen ? { justifyContent: "center" } : {}}
                  >
                    <Icon style={{ color: "currentColor" }} />
                    {isMenuOpen && (
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {el.text}
                      </Typography>
                    )}
                  </Button>
                ) : null;
              })}
              <IconButton className={classes.expandCollapseMenu} onClick={handleChangeDrawerWidth}>
                {isMenuOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Box>
          </Box>
        </Drawer>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      width: "100%",
      marginTop: DRAWER_MIN,
    },
    drawer: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      alignItems: "center",
      width: "100%",
      boxSizing: "border-box",
      marginTop: DRAWER_MIN,
    },
    linkMenu: {
      borderRadius: 0,
      width: "100%",
      height: DRAWER_MIN,
      display: "flex",
      justifyContent: "flex-start",
      padding: theme.spacing(3),
      color: Colors.WHITE,
      background: theme.palette.primary.main,
      "&.MuiButton-contained": {
        background: "#015a9c",
      },
      "&.MuiButton-text:hover": {
        background: "#0a3e67",
      },
    },
    buttonDrawerWidth: {
      boxSizing: "border-box",
      bottom: 10,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    content: {
      overflow: "auto",
      background: theme.palette.background.default,
      boxSizing: "border-box",
      padding: theme.spacing(2),
      height: "calc(100vh - 64px)",
      width: "100%",

      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.background.default,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.light,
        borderRadius: "5px",
      },
    },
    expandCollapseMenu: {
      color: Colors.WHITE,
      borderRadius: 0,
      background: theme.palette.primary.main,
      "&:hover": {
        background: "#0a3e67",
      },
    },
  }),
);
