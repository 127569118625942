import { FC } from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { tableRowsList } from "components/tables/tableRows";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY } from "constants/constants";
import { ReportsListRow } from "./ReportsListRow";
import { useReportsStyles } from "./styles";

interface ReportsListTableProps {}

export const ReportsListTable: FC<ReportsListTableProps> = () => {
  const classes = useReportsStyles();

  const { reportList } = useTypeSelectors((state) => state.dasBoard);

  return (
    <Box className={classes.root}>
      {!reportList.length ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableRowsList.map((TableRows) => (
                    <TableCell className={classes.tableCell} key={TableRows.id}>
                      <Box className={classes.sortCell}>
                        <Typography className={classes.tableCellTypography} variant="body2">
                          {TableRows.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList.length
                  ? reportList.map((row: any) => <ReportsListRow row={row} key={row} />)
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
