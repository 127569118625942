import { FC } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { ReportRecord } from "types/Report";

interface PdfRenderRowProps {
  reportRecord: ReportRecord;
  index: number;
  station: number;
  hasSecondDriver: boolean;
}

export const PdfRenderRow: FC<PdfRenderRowProps> = ({
  reportRecord,
  station,
  index,
  hasSecondDriver,
}) => {
  const { driver, van, route, fleet_number, second_driver, wave_time } = reportRecord;
  const name_driver = driver?.first_name ? `${driver?.first_name} ${driver?.last_name}` : "";

  const name_second_driver = second_driver?.first_name
    ? `${second_driver?.first_name} ${second_driver?.last_name}`
    : "";

  return (
    <TableRow data-testid="testPdfRenderRow">
      <TableCell data-testid="pdfRenderRowIndex">
        <Typography variant="body2">{index + 1}</Typography>
      </TableCell>
      <TableCell data-testid="pdfRenderRowFleetNumber">
        <Typography variant="body2">{van?.number || fleet_number}</Typography>
      </TableCell>
      <TableCell data-testid="pdfRenderRowRoute">
        <Typography variant="body2">{route}</Typography>
      </TableCell>
      <TableCell data-testid="pdfRenderRowRoute">
        <Typography variant="body2">{wave_time}</Typography>
      </TableCell>
      <TableCell data-testid="pdfRenderRowNameDriver">
        <Typography variant="body2">{name_driver}</Typography>
      </TableCell>
      {hasSecondDriver && (
        <TableCell data-testid="pdfRenderRowSecondName">
          <Typography variant="body2">{name_second_driver}</Typography>
        </TableCell>
      )}
    </TableRow>
  );
};
