import { FC, useState } from "react";
import { Box, Button, Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { customToast } from "hooks/toast";
import { PdfRenderTable } from "components/tables/PdfTable/PdfRenderTable";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

interface PdfGenerationModalProps {
  date: string;
  station: string;
  isOpen?: boolean;
}

export const PdfGenerationModal: FC<PdfGenerationModalProps> = ({ date, station, isOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen || false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleDownloadPdf = () => {
    customToast("loading", "Generating PDF...");
    const data = document.getElementById("tablePdf");
    if (data)
      html2canvas(data)
        .then((canvas) => {
          const contentDataURL = canvas.toDataURL("image/png");
          let margin = 0;
          let imgWidth = 210;
          let pageHeight = 295;
          let imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let doc = new jsPDF("p", "mm");
          let position = 0;

          doc.addImage(
            contentDataURL,
            "PNG",
            margin,
            position,
            imgWidth,
            imgHeight,
            "someAlias",
            "FAST",
          );

          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(
              contentDataURL,
              "PNG",
              margin,
              position,
              imgWidth,
              imgHeight,
              "someAlias",
              "FAST",
            );
            heightLeft -= pageHeight;
          }
          doc.save(`DA Tracker ${station} ${moment(date).format("DD MMM yyyy")}.pdf`);
        })
        .catch(() => {
          customToast("error", "Something was wrong!");
        });
  };

  const handleSubmit = () => {
    handleDownloadPdf();
    onClose();
  };

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        className={classes.button}
        onClick={onOpen}
        color="inherit"
      >
        Download PDF
      </Button>
      <Modal data-testid="testPdfGenerationModal" open={open} onClose={onClose}>
        <Box className={classes.modalBox}>
          <Box className={classes.modalTitle}>
            <Typography variant="h3">Table preview</Typography>
            <Button onClick={handleSubmit}>
              <GetAppIcon />
            </Button>
          </Box>
          <PdfRenderTable />
        </Box>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  modalBox: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    width: "950px",
    position: "absolute",
    left: "20%",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "5px",
    },
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: 700,
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  button: {
    marginRight: theme.spacing(1),
    textTransform: "none",
  },
}));
