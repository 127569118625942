import logoPng from "icon/mainLogo.png";
import blueLogoPng from "icon/mainLogoBlue.png";

const LogoPng = ({
  width,
  height,
  isBlue,
}: {
  width: number;
  height: number;
  isBlue?: boolean;
}) => <img src={isBlue ? blueLogoPng : logoPng} width={width} height={height} />;

export default LogoPng;
