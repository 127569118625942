import { Box, Typography, makeStyles } from "@material-ui/core";
import { AuthForm } from "components/elements/AuthForm";
import Background from "icon/background.jpeg";
import LogoPng from "icon/LogoPng";

const VisitPage = () => {
  const classes = useStyles();

  return (
    <Box data-testid="testVisitPage" className={classes.root}>
      <Box className={classes.authBox}>
        <AuthForm />
      </Box>
      <Box className={classes.box}>
        <Box className={classes.textContent}>
          <LogoPng width={300} height={105} />
          <Typography className={classes.title}>
            Quality Delivery is our goal, let’s have a productive day
          </Typography>
          <Typography className={classes.titleSmall}>Stay Safe</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default VisitPage;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    minHeight: "100vh",
    height: "100%",
  },
  authBox: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: "50%",
    height: "100vh",
    boxSizing: "border-box",
    display: "flex",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  textContent: {
    width: "100%",
    padding: theme.spacing(5),
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
  },
  title: {
    fontSize: "35px",
    fontWeight: 600,
    color: "#fff",
    textShadow: "2px 2px #000",
  },
  titleSmall: {
    fontSize: "25px",
    fontWeight: 400,
    color: "#fff",
    textShadow: "2px 2px #000",
  },
  button: {
    width: theme.spacing(40),
    height: theme.spacing(7),
  },
  "@media (max-width: 900px)": {
    root: {
      flexDirection: "column-reverse",
      justifyContent: "start",
    },
    box: {
      width: "100%",
      height: "auto",
    },
    textContent: {
      alignItems: "center",
    },
    title: {
      display: "none",
    },
    titleSmall: {
      display: "none",
    },
    authBox: {
      width: 400,
    },
  },
}));
