export const tableRowsCreate = [
  { field: "small" },
  { field: "very_small", id: "created", label: "#", hasSortLabel: true },
  { field: "big", id: "fleet_group", label: "Fleet Group" },
  { field: "small", id: "fleet_number", label: "Fleet No.", hasSortLabel: true },
  { field: "big", id: "fleet_size", label: "Fleet Size" },
  // { field: "big", id: "fleet_role", label: "Fleet Role", hasSortLabel: true },
  { field: "big", id: "role", label: "Role", hasSortLabel: true },
  { field: "small", id: "route", label: "Route", hasSortLabel: true },
  { field: "big", id: "wave_time", label: "Wave", hasSortLabel: true },
  { field: "big", id: "badge", label: "Badge" },
  { field: "big", id: "driver", label: "DA", hasSortLabel: true},
  { field: "big", id: "transporter_id", label: "Transporter ID" },
  { field: "big", id: "da_status", label: "DA Status", hasSortLabel: true},
  { field: "big", id: "second_driver", label: "DA 2", hasSortLabel: true},
  { field: "big", id: "second_da_status", label: "DA 2 Status", hasSortLabel: true},
  { field: "big", id: "actual_DA", label: "Comment", hasSortLabel: true },
  { field: "small", id: "rabbit", label: "Rabbit Serial", hasSortLabel: true },
  { field: "small", id: "dolly_serial_number", label: "Dolly Serial", hasSortLabel: true },
  { field: "small", id: "rabbit_checklist", label: "Rabbit Checklist", hasSortLabel: true },
  { field: "small", id: "dolly", label: "Dolly", hasSortLabel: true },
  { field: "small", id: "ontime_attendance", label: "Ontime", hasSortLabel: true },
  { field: "small", id: "launchpad_support", label: "LP Support", hasSortLabel: true },
  { field: "small", id: "morning_checklist", label: "Fleet Inspection", hasSortLabel: true },
  { field: "small", id: "rescue", label: "Rescue", hasSortLabel: true },
  { field: "small", id: "violation", label: "Violation", hasSortLabel: true },
  // { field: "small", id: "defects", label: "Defects", hasSortLabel: true },
  { field: "small", id: "number_of_stops", label: "# of Stops", hasSortLabel: true },
  { field: "small", id: "number_of_packages", label: "# of PKGS", hasSortLabel: true },
  // { field: "small", id: "first_stop_time", label: "First Stop", hasSortLabel: true },
  // { field: "small", id: "planned_end_time", label: "Pld End Time", hasSortLabel: true },
  { field: "small", id: "actual_end_time", label: "Act End Time", hasSortLabel: true },
  { field: "small", id: "rescue_given", label: "Rescue Given", hasSortLabel: true },
  { field: "small", id: "rescue_taken", label: "Rescue Taken", hasSortLabel: true },
  { field: "small", id: "uta", label: "UTA", hasSortLabel: true },
  { field: "small", id: "utl", label: "UTL", hasSortLabel: true },
  { field: "small", id: "nsl", label: "NSL", hasSortLabel: true },
  { field: "small", id: "bc", label: "BC", hasSortLabel: true },
  { field: "small", id: "missing", label: "Missing", hasSortLabel: true },
  { field: "small", id: "damage", label: "Damage", hasSortLabel: true },
  { field: "small", id: "fdd", label: "FDD", hasSortLabel: true },
  { field: "small", id: "missort", label: "Missort", hasSortLabel: true },
  { field: "small", id: "oc", label: "OC", hasSortLabel: true },
  { field: "small", id: "others", label: "Others", hasSortLabel: true },
  // { field: "small", id: "cod", label: "COD", hasSortLabel: true },
];

export const tableRowsList = [
  { field: "small", id: "Week", label: "W" },
  { field: "small", id: "report_date", label: "Shift Date" },
  { field: "big", id: "station", label: "Station" },
  { field: "big", id: "created_by", label: "Created By" },
  { field: "small", id: "created", label: "Created Date" },
  { field: "big", id: "edited by", label: "Edited By" },
  { field: "small", id: "modified", label: "Changed Date" },
  { field: "small", id: "Buttons", label: "" },
];

export const inputHistoryTableList = [
  { field: "small", id: "id", label: "ID" },
  { field: "big", id: "File Name", label: "File Name" },
  { field: "big", id: "Uploader Email", label: "Uploader Email" },
  { field: "small", id: "Uploaded", label: "Uploaded Date" },
];

export const fleetSettingsList = [
  { field: "icon", id: "status", label: "Status" },
  { field: "small", id: "station__title", label: "Fixed Station" },
  { field: "small", id: "number", label: "Van" },
  { field: "small", id: "fleet_size__title", label: "Fleet Size" },
  { field: "small", id: "blocking__temp_station__title", label: "Temp station" },
  { field: "big", id: "blocking__assignment", label: "Assigned" },
  { field: "big", id: "description", label: "Description" },
  { field: "big", id: "edited_by", label: "Last Editors" },
  { field: "small", id: "actions", label: "" },
];

export const budgetsTableList = [
  { field: "small", id: "month", label: "Month" },
  { field: "small", id: "station", label: "Station" },
  { field: "small", id: "budget_of_routes", label: "Budget No of Routes" },
  { field: "small", id: "budget_of_deliveries", label: "Budget No of Deliveries" },
  { field: "small", id: "budget_of_da", label: "Budget No of DA" },
  { field: "small", id: "budget_of_fleets", label: "Budget No of Fleet:" },
  { field: "small", id: "delete_budget", label: "" },
];
