import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  Paper,
} from "@material-ui/core";
import { EMPTY } from "constants/constants";
import {
  TABLE_HEADERS_COLUMN_ONE,
  TABLE_HEADERS_COLUMN_TWO,
} from "./actTable";
import { useStyles } from "./styles";
import { FC, useEffect, useState } from "react";

import { useTypeSelectors } from "hooks/useTypeSelectors";
import { UserDataShort } from "types/User";
import api from "api";
import { ActTableRow } from "components/tables/ActTable/actTableRow";
import { 
  updateActTableInfo, 
  setActTableFilters,
  setActTablePagginationPage
} from "actions/actActions";
import { ActData, ActTableHeaderOneCell } from "types/Act";
import { TableSortHead } from "components/tables/tableSortHead";
  
interface PropsType {}
  
export const ActPageTable: FC<PropsType> = () => {
  const classes = useStyles();
  const [superUserList, setSuperUserList] = useState<UserDataShort[]>([]);
  const { dataList, reportListFilters } = useTypeSelectors(
    (state) => state.actTable
  );

  useEffect(()=>{
    api
      .getSuperUsersList()
      .then((res) => {
        if (res.data) {
          setSuperUserList(res.data.results);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },[])

  return (
    <Box className={classes.tableRoot}>
    {!dataList.length ? (
      <Typography variant="h1" className={classes.emptyTable}>
        {EMPTY}
      </Typography>
    ) : (
      <>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_HEADERS_COLUMN_ONE.map((el: ActTableHeaderOneCell) =>
                  el?.toolTipLabel ? (
                    <TableCell colSpan={el.colSpan} className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}>
                      <Tooltip title={<Typography>{el.toolTipLabel}</Typography>}>
                        <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell colSpan={el.colSpan} className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}>
                      <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableSortHead 
              tableHeadersList={TABLE_HEADERS_COLUMN_TWO}
              pagginationAction={setActTablePagginationPage}
              sortAction={setActTableFilters} 
              filters={reportListFilters}
            />

            <TableBody>
              {dataList?.map((el: ActData)=>(
                <ActTableRow
                  key={el?.id}
                  actData={el}
                  superUserList={superUserList}
                  updateActTableInfo={updateActTableInfo}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )}
  </Box>
  );
}; 