import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Box,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReportRecord } from "types/Report";
import { useTypeSelectors } from "../../hooks/useTypeSelectors";
import { InfoBox } from "./InfoBox";
import { Colors } from "constants/constants";
import { NumericFormat } from "react-number-format";

export const ReportTableTotal = () => {
  const classes = useStyles();
  const { currentReport, currentReportDate, currentReportStation } = useTypeSelectors(
    (state) => state.dasBoard
  );

  const reduceData = (field: keyof ReportRecord) => {
    return currentReport.length
      ? currentReport.map((el) => Number(el?.[field])).reduce((prev, curr) => prev + curr)
      : 0;
  };

  const utaResult = reduceData("uta");
  const utlResult = reduceData("utl");
  const nslResult = reduceData("nsl");
  const bcResult = reduceData("bc");
  const missingResult = reduceData("missing");
  const damageResult = reduceData("damage");
  const fddResult = reduceData("fdd");
  const missortResult = reduceData("missort");
  const ocResult = reduceData("oc");
  const othersResult = reduceData("others");
  const codPResult = reduceData("cod");
  const numPackages = reduceData("number_of_packages");

  const total =
    utaResult +
    utlResult +
    nslResult +
    bcResult +
    missingResult +
    damageResult +
    fddResult +
    missortResult +
    ocResult +
    othersResult;
  const percentReturns = Number(numPackages !== 0 ? (100 / numPackages) * total : 0);
  const percentDelivers = Number(100 - percentReturns);

  return (
    <Box className={classes.root}>
      <Box component={Paper} className={classes.filter}>
        <Grid item sm={12} md={6} lg={3} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            MYcourier Daily Summary Report
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={4} style={{ display: "flex", justifyContent: "flex-end" }}>
          <InfoBox label={"Date"} value={currentReportDate} horizontal />
          <InfoBox label={"Station"} value={currentReportStation?.title} horizontal />
        </Grid>
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellSmall}>UTA</TableCell>
              <TableCell className={classes.tableCellSmall}>UTL</TableCell>
              <TableCell className={classes.tableCellSmall}>NSL</TableCell>
              <TableCell className={classes.tableCellSmall}>BC</TableCell>
              <TableCell className={classes.tableCellSmall}>Missing</TableCell>
              <TableCell className={classes.tableCellSmall}>Damage</TableCell>
              <TableCell className={classes.tableCellSmall}>FDD</TableCell>
              <TableCell className={classes.tableCellSmall}>Missort</TableCell>
              <TableCell className={classes.tableCellSmall}>OC</TableCell>
              <TableCell className={classes.tableCellSmall}>Others</TableCell>
              <TableCell className={classes.tableCellSmallBold}>TOTAL</TableCell>
              <TableCell className={classes.tableCellSmallBold}>% of Returns</TableCell>
              <TableCell className={classes.tableCellSmallBold}>% of Delivers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellSmall}>{utaResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{utlResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{nslResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{bcResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{missingResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{damageResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{fddResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{missortResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{ocResult}</TableCell>
              <TableCell className={classes.tableCellSmall}>{othersResult}</TableCell>
              <TableCell className={classes.tableCellSmallBold}>{total}</TableCell>
              <TableCell className={classes.tableCellSmallBold}>
                {percentReturns.toFixed(2)}%
              </TableCell>
              <TableCell className={classes.tableCellSmallBold}>
                {percentDelivers.toFixed(2)}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    border: "none",
    boxShadow: "none",
  },
  filter: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: "wrap",
    boxShadow: "none",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  table: {
    width: "100%",
    minHeight: 70,
    background: "white",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  tableCellSmall: {
    padding: theme.spacing(2),
    textAlign: "center",
    alignContent: "center",
    fontSize: "16px",
  },
  tableCellSmallBold: {
    textAlign: "center",
    alignContent: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
