import { FC } from "react";
import { useDispatch } from "react-redux";
import { IconButton, TableCell, TableRow, Typography, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Eye from "@material-ui/icons/RemoveRedEye";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { removeReportAction, notifyReportDriversAction } from "actions/daDailyReportsAction";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { Colors, SUPERADMIN } from "constants/constants";
import {
  DeleteShiftDialog,
  SendDriversMessageDialog,
} from "components/modals/modalContent/ShiftDialogs";
import { useNav } from "hooks/useNav";
import { ReportShort } from "types/Report";
import moment from "moment";
import { useReportsStyles, useReportStyles } from "./styles";
import { ModalWindow } from "components/templateComponents/modalWindow";

interface ReportsListRowProps {
  row: ReportShort;
}

export const ReportsListRow: FC<ReportsListRowProps> = ({ row }) => {
  const classes = useReportsStyles();
  const dispatch = useDispatch();
  const { viewReport } = useNav();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { id, dispatcher, edited_by, created, modified, report_date, station, week_number } = row;

  const handleSendNotify = () => {
    dispatch(notifyReportDriversAction(id));
  };

  const handleDeleteTable = () => {
    dispatch(removeReportAction(id));
  };

  const handleEditTable = () => {
    viewReport(id);
  };

  return (
    <TableRow hover>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2" style={{ color: "lightgrey", opacity: 0.8 }}>
          {`${+week_number + 1}`.padStart(2, "0")}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{moment(report_date).format("DD-MM-yyyy")}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{station?.title}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">
          {dispatcher?.first_name} {dispatcher?.last_name}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{moment(created).utcOffset(-300).format("DD-MM-yyyy")}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">
          {edited_by?.first_name} {edited_by?.last_name}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{moment(modified).utcOffset(-300).format("DD-MM-yyyy")}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Box style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
          <ModalWindow
            modal_name={`whats-up-${report_date}-${station?.title}-modal`}
            modalTitle={"Send notification"}
            isIconButton
            hasIcon
            theme="green"
            icon={<WhatsAppIcon />}
          >
            <SendDriversMessageDialog onSubmit={handleSendNotify} />
          </ModalWindow>
          <EditButton onSubmit={handleEditTable} />
          {userData.role === SUPERADMIN && (
            <ModalWindow
              modal_name={`delete-shift-${report_date}-${station?.title}-modal`}
              modalTitle={"Delete Shift"}
              modalDescription={"Attention! This action will delete this entry forever"}
              modalText={`Are you sure to delete shift for ${station?.title} on ${report_date} ?`}
              isIconButton
              hasIcon
              theme="red"
              icon={<DeleteIcon />}
            >
              <DeleteShiftDialog onSubmit={handleDeleteTable} />
            </ModalWindow>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const EditButton = ({ onSubmit }: { onSubmit: () => void }) => {
  const classes = useReportStyles();
  return (
    <IconButton className={classes.editButton} onClick={onSubmit} color="primary">
      <Eye style={{ fill: Colors.BLUE }} />
    </IconButton>
  );
};
